@import url('https://fonts.googleapis.com/css?family=Poppins');


html,
body,
#root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Poppins';
}

*,
*:after,
*:before {
  box-sizing: border-box;
}



.faqs-div {
  padding-left: 10%;
  padding-right: 40%;
  font-size: large;
  text-align: left;

}

.bio-div {
  padding-left: 5%;
  padding-right: 45%;
  padding-top: 9%;
  font-size: 34px;
  text-align: left;
  border-color: red;
  border-width: 10px;
  color: white;
  line-height: 1.4;

}